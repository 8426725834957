import React from 'react';
import Heading from '../components/atoms/Heading';
import Text from '../components/atoms/Text';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const BlogPage = () => (
  <Layout>
    <SEO title="Blog" />
    <Heading>Blog</Heading>
    <Text mt={1}>This is the place I use to write down anything interesting.</Text>
    <Text textAlign="center" mt={24}>
      I&apos;m still working on this page. Please check back later.
    </Text>
  </Layout>
);

export default BlogPage;
